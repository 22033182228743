<template>
  <div>
    <!--begin::Dashboard-->
    <div class="row">
      <div class="col-12">
        <div class="card card-custom card-stretch gutter-b">
          <!--begin::Header-->
          <div class="card-header border-0 pt-5">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label font-weight-bolder text-dark">
                Factures
              </span>
            </h3>
            <div class="card-toolbar">

              <b-button v-if="csvLoading" variant="secondary" class="font-weight-bolder d-flex flex-row align-items-center" disabled>
                <b-spinner small /> Télécharger en Excel
              </b-button>
              <b-button v-else @click.prevent="exportCsv" variant="secondary" class="font-weight-bolder d-flex flex-row align-items-center">
                <i class="fas fa-file-download mr-4" /> Télécharger en Excel
              </b-button>

            </div>
          </div>
          <!--end::Header-->
          <!--begin::Body-->
          <div class="card-body py-4">
            <!--begin::Table-->
            <vue-good-table
              mode="remote"
              :total-rows="totalRecords"
              :is-loading.sync="isTableLoading"
              :pagination-options="{ enabled: true }"
              :sort-options="{
                enabled: false,
                initialSortBy: serverParams.sort,
              }"
              :rows="rows"
              :columns="columns"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
              @on-row-click="toInvoice"
              styleClass="custom-table clickable"
            >
              <div slot="loadingContent" class="text-center">Chargement...</div>
              <div slot="emptystate" class="text-center">
                Aucun élément à afficher
              </div>
              <template slot-scope="props" slot="table-row">
                <!-- Column: Status -->
                <span v-if="props.column.field === 'isRefund'">
                  <b-badge :variant="props.row.isRefund ? 'danger' : 'success'">
                    <span v-if="props.row.isRefund">
                      Avoir
                    </span>
                    <span v-else>
                      Facture
                    </span>
                  </b-badge>
                  <small v-if="props.row.canceledBy">
                    <br>
                    Annulée par N°{{ props.row.canceledBy.number }}
                  </small>
                </span>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <b-form-select
                      v-model="serverParams.perPage"
                      :options="['10', '25', '50', '100']"
                      class="mr-2 custom-select-sm"
                      @input="
                        (value) =>
                          props.perPageChanged({ currentPerPage: value })
                      "
                    />
                    <span class="text-nowrap">
                      factures par page
                    </span>
                  </div>
                  <div>
                    <b-pagination
                      :value="serverParams.page"
                      :total-rows="props.total"
                      :per-page="serverParams.perPage"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="
                        (value) => props.pageChanged({ currentPage: value })
                      "
                    >
                      <template #prev-text>
                        <i class="fas fa-chevron-left"></i>
                      </template>
                      <template #next-text>
                        <i class="fas fa-chevron-right"></i>
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
    <!--end::Dashboard-->

    <b-modal
      v-model="modal.show"
      :title="modal.title"
      hide-footer
      no-close-on-backdrop
    >
      <div class="d-block">
        <b-form id="modal-form" @submit.prevent="onSubmitItem">
          <b-form-group label="Client :">
            <v-select
              v-model="modal.item.customer"
              @search="fetchCustomerOptions"
              :options="customerOptions"
              :filterable="false"
              label="id"
              placeholder="Rechercher un client (nom ou adresse email)..."
              required
            >
              <template slot="no-options">Aucun client trouvé</template>
              <template slot="option" slot-scope="option">
                {{ option.firstname }} {{ option.lastname }} ({{
                  option.email
                }})
              </template>
              <template slot="selected-option" slot-scope="option">
                {{ option.firstname }} {{ option.lastname }} ({{
                  option.email
                }})
              </template>
            </v-select>
          </b-form-group>

          <div class="text-right">
            <b-button class="mr-2" @click.prevent="hideModal" variant="light-primary"
              >Annuler</b-button
            >
            <b-button type="submit" variant="primary">Créer la vente</b-button>
          </div>
        </b-form>
      </div>
    </b-modal>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "invoices",
  data() {
    return {
      columns: [
        {
          label: "Date",
          field: "invoiceDate",
          formatFn: this.dateToStr,
          sortable: true,
          width: "100px",
        },
        {
          label: "N°",
          field: "number",
          sortable: true,
          width: "90px",
          filterOptions: {
            enabled: true,
            placeholder: 'N°',
            fullMatch: true,
          },
        },
        // { label: "Type", field: "attachedToType", formatFn: this.typeToStr, sortable: true },
        {
          label: "Type",
          field: "isRefund",
          sortable: false,
          filterOptions: {
            enabled: true,
            placeholder: 'Tous',
            fullMatch: true,
            filter: true,
            filterDropdownItems: [
              { value: false, text: "Facture" },
              { value: true, text: "Avoir" },
            ],
          }
        },
        {
          label: "Client",
          field: "customer.name",
          sortable: true,
          filterOptions: {
            enabled: true,
            placeholder: 'Rechercher...',
          },
        },
        {
          label: "Total HT",
          field: "totalWithoutVat",
          sortable: true,
          filterOptions: {
            enabled: true,
            placeholder: 'Total HT',
            fullMatch: true,
          },
          formatFn: (e) => `${e.toFixed(2)}€`,
        },
        {
          label: "Remise",
          field: "discount",
          sortable: true,
          formatFn: (e) => e ? `${e.toFixed(2)}%` : '',
          filterOptions: {
            enabled: true,
            placeholder: 'Remise (%)',
            fullMatch: true,
          },
        },
        {
          label: "Total TTC",
          field: "totalWithVat",
          sortable: true,
          filterOptions: {
            enabled: true,
            placeholder: 'Total TTC',
            fullMatch: true,
          },
          formatFn: (e) => `${e.toFixed(2)}€`,
        },
      ],
      rows: [],
      recents: [],
      totalRecords: 0,
      isTableLoading: true,
      csvLoading: false,
      serverParams: {
        columnFilters: {},
        sort: {
          field: "invoiceDate",
          type: "desc",
        },
        page: 1,
        perPage: 10,
      },
      modal: {
          show: false,
          title: "Nouvelle vente",
          item: {
            id: null,
            reference: null,
            name: null,
            price: null,
            showTotalWithVat: true,
            discount: null,
            doneAt: null,
            destocked: null,
            status: "PENDING",
            canceled: null,
            customer: null,
          },
      },
      customerOptions: [],
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Ventes" }]);
  },
  methods: {
    toInvoice(props) {
      this.$router.push({ name: "invoice", params: { id: props.row.id } });
    },
    typeToStr(type) {
      if (type === "Sale") return "Vente";
      else if (type === "Quotation") return "Devis";
    },
    fetchCustomerOptions(search, loading) {
      if (!search.length) return;
      loading(true);
      ApiService.query("/customers", {
        params: { lastname_contains: search, email_contains: search, _or: 1 },
      })
        .then(({ data }) => {
          this.customerOptions = data.results;
          loading(false);
        })
        .catch(() => {
          loading(false);
        });
    },
    hideModal() {
      this.modal.show = false;
    },
    newItem() {
        this.modal.item = { id: null, reference: null, name: null, price: null, showTotalWithVat: true, discount: null, doneAt: null, destocked: null, status: "PENDING", canceled: null, customer: null };
        this.modal.title = "Nouvelle facture";
        this.modal.show = true;
    },
    onSubmitItem() {
        this.modal.item.customer.name = `${this.modal.item.customer.firstname} ${this.modal.item.customer.lastname}`;
        this.modal.item.customer.address = this.modal.item.customer.address;

      ApiService.post(`/invoices`, this.modal.item).then(({ data }) => {
        this.$router.push({ name: "invoice", params: { id: data.id } });
        this.modal.show = false;
      });
    },
    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps };
    },
    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.fetchItemsFromApi();
    },
    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.onPageChange({ currentPage: 1 });
    },
    onSortChange([params]) {
      this.updateParams({
        sort: {
          field: params.field,
          type: params.type,
        },
      });
      this.onPageChange({ currentPage: 1 });
    },
    onColumnFilter(params) {
      this.updateParams(params);
      this.onPageChange({ currentPage: 1 });
    },
    getApiParams() {
      const tableSearch = {};
      Object.keys(this.serverParams.columnFilters).forEach((e) => {
        if (!this.serverParams.columnFilters[e]) return;

        if (this.columns.find(column => column.field == e)?.filterOptions?.fullMatch) {
          tableSearch[e] = this.serverParams.columnFilters[e];
        } else {
          tableSearch[`${e}_contains`] = this.serverParams.columnFilters[e];
        }
      });

      return {
        ...tableSearch,
        limit: this.serverParams.perPage,
        page: this.serverParams.page,
        sortBy: `${this.serverParams.sort.field}:${this.serverParams.sort.type}`,
        populate: "canceledBy",
      };
    },
    /**
     * Fetch models from API and display them in the table
     */
    fetchItemsFromApi() {
      this.isTableLoading = true;
      ApiService
        .query("/invoices", { params: this.getApiParams() })
        .then((res) => {
          this.rows = res.data.results;
          this.totalRecords = res.data.totalResults;
          this.isTableLoading = false;
        })
        .catch(() => {
          this.isTableLoading = false;
        });
    },
    /**
     * Export table to CSV file
     */
    exportCsv() {
      this.csvLoading = true;
      ApiService
        .query("/invoices", { params: { ...this.getApiParams(), toCsv: 1 } })
        .then(({ data }) => {
          const anchor = document.createElement('a');
          anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(data);
          anchor.target = '_blank';
          anchor.download = 'factures.csv';
          anchor.click();
          this.csvLoading = false;
        })
        .catch(() => {
          this.csvLoading = false;
        });
    },
  },
};
</script>